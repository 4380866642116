import React, { useState, useEffect } from "react"
import { useModal } from "./modalStore"

import "./style.scss"

const Modal = () => {
  const [modalContent, setModalContent] = useState("")
  const [getModalStore, , subscribe, unsubscribe] = useModal()

  const storeToState = () => {
    setModalContent(getModalStore())
  }

  useEffect(() => {
    //...sub here
    subscribe(storeToState)
    return () => {
      //...unsub here
      unsubscribe(storeToState)
    }
  })

  return modalContent ? <section className="modal">{modalContent}</section> : ""
}

export default Modal
