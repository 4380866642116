/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./content_blocks/global/header"
import LargeHeader from "./content_blocks/global/largeHeader"
import Footer from "./content_blocks/global/footer"
import Modal from "./elements/modal"

const Layout = ({ headerData, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {headerData?.useLargeHeader ? (
        <LargeHeader
          siteTitle={data.site.siteMetadata?.title || `Title`}
          headerData={headerData}
        />
      ) : (
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          bannerImage={headerData?.bannerImage?.sourceUrl}
        />
      )}

      <main>{children}</main>
      <Modal />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
