import React, { useState } from "react"
import PageLink from "../../elements/pageLink"

import "./dropdownNavigation.scss"

const DropdownNavigation = ({ dropdownNavigation }) => {
  const [selectedNavItem, setSelectedNavItem] = useState({
    url: "",
    text: "Select a service",
  })

  return (
    <section className="serviceNavigation">
      <div className="serviceNavigation-container">
        <label htmlFor="serviceSelect">I’m looking for</label>
        <DropdownElement
          dropdownNavigation={dropdownNavigation}
          selectedNavItem={selectedNavItem}
          setSelectedNavItem={setSelectedNavItem}
        />
        <PageLink
          className="serviceNavigation-submit"
          href={selectedNavItem.url}
        >
          Go
        </PageLink>
      </div>
    </section>
  )
}

const DropdownElement = props => {
  const shortcuts = props.dropdownNavigation
    ? props.dropdownNavigation.map(shortcut => {
      return {
        url: shortcut?.link?.url,
        text: shortcut?.link?.title,
      }
    })
    : { url: "", text: "No menu items assigned" }

  const [activeMenu, setActiveMenu] = useState(false)

  function changeSelectedNavItem(selectedItem) {
    props.setSelectedNavItem(selectedItem)
    deactivateMenu()
  }

  function deactivateMenu() {
    setActiveMenu(false)
  }

  return (
    <div className="dropdown-wrapper">
      <button
        className="dropdown"
        onClick={() => {
          setActiveMenu(!activeMenu)
        }}
      >
        <div className="dropdown-item active">{props.selectedNavItem.text}</div>
      </button>
      {activeMenu && (
        <div className="dropdown-menu">
          {shortcuts.map(shortcutButton => {
            return (
              <button
                className="dropdown-menu-item"
                onClick={() => {
                  changeSelectedNavItem(shortcutButton)
                }}
              >
                {shortcutButton.text}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default DropdownNavigation
