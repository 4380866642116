import React from "react"
// import { useStaticQuery, graphql } from "gatsby"

const ImageBlock = ({ imageSrc, imageAlt, wrapperClass, imageClass }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     fallBackImage: file(relativePath: { eq: "fallback.svg" }) {
  //       publicURL
  //     }
  //   }
  // `)

  let thisImageClass = "imageWrapper " + (wrapperClass ? wrapperClass : "")
  let image = <img src={imageSrc} alt={imageAlt} className={imageClass} />

  //if there is no image source
  if (!imageSrc) {
    image = (
      <img
        src="/pageImages/fallback.svg"
        alt={"Fallback"}
        className={imageClass}
      />
    )
  }

  return (
    <div className={thisImageClass}>
      {/* eventually use gatsby image plugins */}
      {/* <StaticImage alt={imageAlt} src={"/illustrations/woman_typing.svg"} /> */}
      {image}
    </div>
  )
}

export default ImageBlock
