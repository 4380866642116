import React from "react"

import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/splide/dist/css/splide.min.css"
import TextBlock from "../../../elements/textblock"
import HeadingBlock from "../../../elements/headingBlock"
import ImageBlock from "../../../elements/imageBlock"

const Slider = ({ slides, sliderSpeed }) => {
  const splideOptions = {
    type: "loop",
    rewind: true,
    arrows: true,
    pagination: false,
    perPage: 1,
    perMove: 1,
    drag: true,
    width: "100vw",
    autoplay: true,
    interval: sliderSpeed,
  }

  //fix splide build error
  if (typeof window === "undefined") {
    return (
      <section>
        <p>Failed Server Render</p>
      </section>
    )
  }
  try {
    const slidesData = slides.map((slide, index) => {
      return {
        index: index,
        imageUrl: slide?.image?.sourceUrl,
        heading: slide?.heading,
        bodyText: slide?.bodyText,
      }
    })

    return (
      <Splide className="largeHeader-slider" options={splideOptions}>
        {slidesData.map(slide => {
          return Slide(slide)
        })}
      </Splide>
    )
  } catch (err) {
    return (
      <div>
        <TextBlock text={err} />
      </div>
    )
  }
}

const Slide = slide => {
  return (
    <SplideSlide key={slide.index}>
      <ImageBlock imageSrc={slide.imageUrl} />
      <div className="splide__slide__content">
        <div className="splide__slide__content__container">
          <HeadingBlock text={slide.heading} />
          <TextBlock text={slide.bodyText} />
        </div>
      </div>
    </SplideSlide>
  )
}
export default Slider
