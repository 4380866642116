import React from "react"

import "./secondaryHeader.scss"

const SecondaryHeader = () => (
  <section className="secondaryHeader">
    <ul>
      <li>
        <a href="tel:1800622683" target="_blank" rel="noreferrer"><img src={"/icons/phone-alt.svg"} alt="Phone" /></a>
      </li>
      <li>
        <a href="mailto:info@unityclaims.com.au" target="_blank" rel="noreferrer"><img src={"/icons/envelope-alt.svg"} alt="Email" /></a>
      </li>
    </ul>
  </section>
)

export default SecondaryHeader
