import React from "react"

import ImageBlock from "./imageBlock"

const TextBlock = ({ text, wrapperClass }) => {
  // let thisWrapperClass =
  //   "textWrapper whitespace-pre-line " + (wrapperClass ? wrapperClass : null)
  let thisWrapperClass = "textWrapper " + (wrapperClass ? wrapperClass : null)

  if (Array.isArray(text)) {
    return (
      <div className={thisWrapperClass}>
        {text.map(textElement => {
          return TextBlockElement(textElement)
        })}
      </div>
    )
  } else {
    //this is to be depecrated so that only object arrays are accepted
    return (
      <div
        className={thisWrapperClass}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }
}

function TextBlockElement(textElement) {
  const editedText = textElement?.text?.replace(/(\r\n|\n|\r)/gm, "")

  switch (textElement.type) {
    case "leadingText":
      return (
        <div
          key="leadingText"
          className="leadingText"
          dangerouslySetInnerHTML={{ __html: editedText }}
        />
      )
    case "bodyText":
      return (
        <div
          key="bodyText"
          className="body"
          dangerouslySetInnerHTML={{ __html: editedText }}
        />
      )
    case "image":
      if (!textElement.image.sourceUrl)
        return <div>No image source provided</div>

      return (
        <ImageBlock
          key="imageBlock"
          imageSrc={textElement?.image?.sourceUrl}
          imageAlt={textElement?.image?.altText}
        />
      )
    default:
      return <div>Unknown Type</div>
  }
}

export default TextBlock
