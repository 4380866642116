import React, { useState, useEffect } from "react"
//import PropTypes from "prop-types"

import SecondaryHeader from "../secondaryHeader"
import Logo from "../logo/logo"
import Menu from "../menu"

import MegaMenu from "../megamenu"
import Slider from "./slider"

import DropdownNavigation from "../../dropdownNavigation"
import PageLink from "../../../elements/pageLink"
import ImageBlock from "../../../elements/imageBlock"
import TextBlock from "../../../elements/textblock"

import "./largeHeader.scss"

const LargeHeader = ({ headerData }) => {
  const [megaMenuState, setMegaMenuState] = useState()
  //const [lastMegaMenuItem, setLastMegaMenuItem] = useState()
  //bufferState is used to keep the megamenu alive when the user briefly hovers off of it
  const [bufferState, setBufferState] = useState(false)
  const delay = 0.25

  useEffect(() => {
    if (!bufferState) {
      let closeBuffer = setTimeout(() => setMegaMenuState(), delay * 1000)
      return () => {
        clearTimeout(closeBuffer)
      }
    } else {
      setMegaMenuState(bufferState)
    }
  })

  function activateMegaMenu(menuItem) {
    // console.log("Activate MegaMenu")
    setBufferState(menuItem)
    // console.log(bufferState)
  }

  function deactivateMegaMenu() {
    // console.log("Deactivate MegaMenu")
    setBufferState()
  }

  return (
    <>
      <header
        // style={{ backgroundImage: "url(/banners/home-banner.jpg)" }}
        className="largeHeader"
      >
        <div className="largeHeader-container">
          <SecondaryHeader />
          <div className="largeHeader-header">
            <Logo />
            <Menu
              activateMegaMenu={activateMegaMenu}
              deactivateMegaMenu={deactivateMegaMenu}
            />
          </div>
          <MegaMenu
            megaMenuState={megaMenuState}
            activateMegaMenu={activateMegaMenu}
            deactivateMegaMenu={deactivateMegaMenu}
          />

          <div className="largeHeader-slider-wrapper">
            <Slider
              slides={headerData?.bannerSliders}
              sliderSpeed={
                headerData?.bannerSpeed ? headerData.BannerSpeed : 9000
              }
            />
          </div>

        </div>

        <div className="largeHeader-footer">
          <FooterContent quicklinks={headerData.quicklinks} />
        </div>
      </header>
      <DropdownNavigation dropdownNavigation={headerData?.dropdownNavigation} />
    </>
  )
}

// construct footer
const FooterContent = ({ quicklinks }) => {
  try {
    if (!quicklinks) throw "No quicklinks have been assigned"

    return quicklinks.map((quicklink, index) => {
      return (
        <PageLink key={index} href={quicklink?.link?.url} target={quicklink?.link?.target}>
          <ImageBlock imageSrc={quicklink?.image?.sourceUrl} imageAlt="" />
          <TextBlock className="button-text" text={quicklink?.link?.title} />
        </PageLink>
      )
    })
  } catch (err) {
    return <div className="errorMessage">{err}</div>
  }
}

export default LargeHeader
