import React from "react"
import FilterLink from "../../utils"

const PageLink = ({ href, target, className, children }) => {
  return (
    <a
      href={FilterLink(href)}
      target={target ? target : "_self"}
      className={className ? className : ""}
    >
      {children}
    </a>
  )
}

export default PageLink
