import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

import Pagelink from "../../../elements/pageLink"
import "./menu.scss"

const Menu = props => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalInformation {
          generalInformation {
            menuItems {
              hasMegamenu
              menuItem {
                target
                title
                url
              }
              megamenuItems {
                heading
                bodyText
                megamenuImage {
                  sourceUrl
                  altText
                }
                menuItems {
                  menuItem {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const menuData = data?.wp?.generalInformation?.generalInformation?.menuItems
    ? data.wp.generalInformation.generalInformation.menuItems
    : null

  const menuItems = processData(menuData)

  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const openMenu = () => {
    setMobileNavOpen(true)
    document.body.style.overflow = "hidden"
  }

  const closeMenu = () => {
    setMobileNavOpen(false)
    document.body.style.overflow = "auto"
  }

  const MobileMenuList = props => {
    if (!props.menuItems) return <li>No menu items assigned</li>
    return props.menuItems.map((menuItem, index) => {
      return (
        <li>
          <Pagelink onClick={() => closeMenu()} href={menuItem.url}>
            {menuItem.title}
          </Pagelink>
        </li>
      )
    })
  }

  return (
    <div className="headerMenu">
      <div className="mobileNav">
        <button onClick={openMenu} className="mobileNavButton" type="button">
          <img src={"/icons/icon-menu.svg"} alt="Menu" />
        </button>
        <div className={`mobileNavMenu ${mobileNavOpen ? " showMenu" : ""}`}>
          <div className="inner">
            <button
              onClick={closeMenu}
              className="mobileCloseButton"
              type="button"
            >
              <img src={"/icons/icon-close.svg"} alt="Close" />
            </button>
            <ul className="mobileNavMenuList">
              <MobileMenuList menuItems={menuItems} />
            </ul>
          </div>
        </div>
      </div>
      <nav className="mainNavigation">
        <ul>
          <MenuList
            menuItems={menuItems}
            activateMegaMenu={props.activateMegaMenu}
            deactivateMegaMenu={props.deactivateMegaMenu}
          />
        </ul>
      </nav>
      {/* <div className="headerUtilities">
        <ul>
          <li>
            <img src={"/icons/magnifyGlass.svg"} alt="Search Icon" />
          </li>
        </ul>
      </div> */}
    </div>
  )
}

const MenuList = props => {
  if (!props.menuItems) return <li>No menu items assigned</li>
  return props.menuItems.map((menuItem, index) => {
    return menuItem.hasMegaMenu ? (
      <li
        key={index}
        onMouseEnter={() => {
          props.activateMegaMenu(menuItem)
        }}
        onMouseLeave={() => {
          props.deactivateMegaMenu()
        }}
      >
        <Pagelink href={menuItem.url}>{menuItem.title}</Pagelink>
      </li>
    ) : (
      <li>
        <Pagelink href={menuItem.url}>{menuItem.title}</Pagelink>
      </li>
    )
  })
}

function processData(dataArray) {
  if (!dataArray) return null
  return dataArray.map(data => {
    const { hasMegamenu, menuItem, megamenuItems } = data

    //default menu item
    let newMenuItem = {
      url: menuItem.url,
      title: menuItem.title,
      hasMegaMenu: hasMegamenu,
    }

    //add megamenu fields if it exists
    if (hasMegamenu) {
      newMenuItem.megaMenuItems = {
        heading: megamenuItems.heading,
        bodyText: megamenuItems.bodyText,
        imageSrc: megamenuItems.megamenuImage.sourceUrl,
        menuItems: !megamenuItems.menuItems
          ? null
          : megamenuItems?.menuItems.map(menuItem => {
              return {
                url: menuItem?.menuItem?.url,
                title: menuItem?.menuItem?.title,
              }
            }),
      }
    }

    return newMenuItem
  })
  //   {
  //     url: "/claims-services",
  //     title: "Our Services",
  //     hasMegaMenu: true,
  //     megaMenuItems: {
  //       heading: "Our Services",
  //       bodyText:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s",
  //       imageSrc: "/pageImages/claims-services.jpg",
  //       menuItems: [
  //         {
  //           url: "/claims-services/claims-management",
  //           title: "Claims Management",
  //         },
  //         {
  //           url: "/claims-services/fleet-solutions/",
  //           title: "Fleet Solutions",
  //         },
  //         {
  //           url: "/claims-services/repair-network/",
  //           title: "Repair Network",
  //         },
  //       ],
  //     },
  //   },
}
export default Menu
