import React from "react"

const HeaderBlock = ({ text, level, headingClass, wrapperClass }) => {
  let heading = ""

  let thisHeadingClass = "headingWrapper " + wrapperClass

  switch (level) {
    case "1":
      heading = <h1 className={headingClass}>{text}</h1>
      break
    case "2":
      heading = <h2 className={headingClass}>{text}</h2>
      break
    case "3":
      heading = <h3 className={headingClass}>{text}</h3>
      break
    case "4":
      heading = <h4 className={headingClass}>{text}</h4>
      break
    case "5":
      heading = <h5 className={headingClass}>{text}</h5>
      break
    case "6":
      heading = <h6 className={headingClass}>{text}</h6>
      break
    case "subtitle":
      heading = <span className={headingClass}>{text}</span>
      thisHeadingClass = "subtitle " + thisHeadingClass
      break
    default:
      heading = <h2 className={headingClass}>{text}</h2>
  }

  return <div className={thisHeadingClass}>{heading}</div>
}

export default HeaderBlock
