import { useStaticQuery, graphql } from "gatsby"

export const CreateLocalLink = (menuItem, wordPressUrl, blogURI = "blog/") => {
  const { url, connectedObject } = menuItem

  if (url === "#") {
    return null
  }

  let newUri = url.replace(wordPressUrl, "")

  if (connectedObject && connectedObject.__typename === "WPGraphQL_Post") {
    newUri = blogURI + newUri
  }

  return newUri
}

const FilterLink = link => {
  const SITE_URL = useStaticQuery(graphql`
    query {
      wp {
        allSettings {
          generalSettingsUrl
        }
      }
    }
  `)

  const WORDPRESS_URL = SITE_URL.wp.allSettings.generalSettingsUrl

  if (!link) return link

  try {
    let newLink = link

    const patternFilter = [
      { query: "/claims-page", replace: "" },
      { query: WORDPRESS_URL, replace: "" },
    ]

    patternFilter.forEach(pattern => {
      newLink = newLink.replace(pattern.query, pattern.replace)
    })

    return newLink
  } catch (err) {
    console.log(err.message)
  }
}

export default FilterLink

// convert to readable date
export function convertToReadableDate(date) {
  const newDate = new Date(date)
  // returns in the following format: January 1, 2022
  return `${getMonthName(
    newDate.getMonth()
  )} ${newDate.getDate()}, ${newDate.getFullYear()}`
}

// get month date from index
function getMonthName(index) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  return months[index]
}

// convert string to an excerpt
export function convertToExerpt(str) {
  return shorten(str.replace(/<[^>]*>?/gm, ""), 125)
}

// Shorten a string to less than maxLen characters without truncating words.
function shorten(str, maxLen, separator = " ") {
  if (str?.length <= maxLen) return str
  return str.substr(0, str.lastIndexOf(separator, maxLen))
}
