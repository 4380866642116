import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import SecondaryHeader from "../secondaryHeader"
import Logo from "../logo/logo"
import Menu from "../menu"
import MegaMenu from "../megamenu/megamenu"

import "./header.scss"

const Header = props => {
  const bannerImage = props.bannerImage
    ? `url(${props.bannerImage})`
    : "url(/banners/banner-home.jpg)"

  const [megaMenuState, setMegaMenuState] = useState()
  //const [lastMegaMenuItem, setLastMegaMenuItem] = useState()
  //bufferState is used to keep the megamenu alive when the user briefly hovers off of it
  const [bufferState, setBufferState] = useState(false)
  const delay = 0.25

  useEffect(() => {
    if (!bufferState) {
      let closeBuffer = setTimeout(() => setMegaMenuState(), delay * 1000)
      return () => {
        clearTimeout(closeBuffer)
      }
    } else {
      setMegaMenuState(bufferState)
    }
  })

  function activateMegaMenu(menuItem) {
    // console.log("Activate MegaMenu")
    setBufferState(menuItem)
    // console.log(bufferState)
  }

  function deactivateMegaMenu() {
    // console.log("Deactivate MegaMenu")
    setBufferState()
  }

  return (
    <>
      <header style={{ backgroundImage: bannerImage }} className="header">
        <div className="header-container">
          <SecondaryHeader />
          <div className="header-main">
            <Logo />
            <Menu
              activateMegaMenu={activateMegaMenu}
              deactivateMegaMenu={deactivateMegaMenu}
            />
          </div>
          <MegaMenu
            megaMenuState={megaMenuState}
            activateMegaMenu={activateMegaMenu}
            deactivateMegaMenu={deactivateMegaMenu}
          />
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
