import React from "react"

import "./logo.scss"

const Logo = () => (
  <section className="logoContainer">
    <a href="/">
      <img className="logoImage" src={"/logos/unityClaims-white.svg"} alt="Unity Claims" />
    </a>
  </section>
)

export default Logo
