import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import HeaderBlock from "../../../elements/headingBlock"
import PageLink from "../../../elements/pageLink"
import TextBlock from "../../../elements/textblock"
import "./footer.scss"

// const footerText =
//   "Unity is a trusted partner and major contributor of investing capital into the Motor Vehicle Repair Industry to enhance and support its sustainability, quality, employment and training."

const contactDetails = {
  address:
    "Unity Claims Management<br />U2/8 Gibberd Road,<br />Balcatta, WA, 6021,<br />Australia",
  // phone: "1800 622 683",
  // email: "info@unityclaims.com.au",
}

// const quickLinks = [
//   { title: "Claims", link: "#", target: "_self" },
//   { title: "Book a service", link: "#", target: "_self" },
//   { title: "Our Services", link: "#", target: "_self" },
//   { title: "About Us", link: "#", target: "_self" },
//   { title: "Careers", link: "#", target: "_self" },
//   { title: "News", link: "#", target: "_self" },
//   { title: "GICOP/AFSL", link: "#", target: "_self" },
// ]

const copyrightText = [
  "© Copyright - Unity Claims. 2024",
  "All rights reserved.",
]
// const copyrightLinks = [
//   { title: "Privacy Policy", link: "#", target: "_self" },
//   { title: "Modern Slavery Act Statement", link: "#", target: "_self" },
//   { title: "Terms of use", link: "#", target: "_self" },
//   { title: "Cookie Preferences", link: "#", target: "_self" },
// ]

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalInformation {
          generalInformation {
            footerText
            phoneNumber
            email
            footerMenu {
              menuItem {
                target
                title
                url
              }
            }
            copyrightLinks {
              menuItem {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const footerData = data?.wp?.generalInformation?.generalInformation
    ? data.wp.generalInformation.generalInformation
    : null

  const quickLinks = footerData.footerMenu
  const copyrightLinks = footerData.copyrightLinks

  // const footerItems = processData(footerData)

  return (
    <footer>
      <section className="footer-widgets">
        <div className="footer-widgets-widget">
          <div className="footer-widgets-widget-content">
            <img
              src="/logos/unityClaims-vertical.svg"
              alt="Unity Claims Logo"
              className="mb-4 xl:mb-8"
            />
            <TextBlock text={footerData?.footerText} />
          </div>
        </div>
        <div className="footer-widgets-widget">
          <HeaderBlock text="Find Us" level="4" />
          <address>
            <TextBlock text={contactDetails.address} />
          </address>

          {footerData.email && (
            <address>
              <strong>Email: </strong>
              <a href={`mailto:${footerData.email}`}>{footerData.email}</a>
            </address>
          )}

          {footerData.phoneNumber && (
            <address>
              <strong>Tel: </strong>
              {footerData.phoneNumber}
            </address>
          )}
        </div>
        <div className="footer-widgets-widget">
          <HeaderBlock text="Quicklink" level="4" />
          <nav>{getQuicklinks(quickLinks)}</nav>
        </div>
      </section>
      <section className="copyright">
        <div className="copyright-container">
          <div className="copyright-textContent">
            {copyrightText.map((text, index) => (
              <div key={index} className="text">
                {text}
              </div>
            ))}
          </div>
          <div className="copyright-links">
            {getCopyrightLinks(copyrightLinks)}
          </div>
        </div>
      </section>
    </footer>
  )
}

function getQuicklinks(quickLinks) {
  if (!quickLinks)
    return <li className="errorMessage">No Quick links assigned</li>

  return (
    <ul>
      {quickLinks.map((quickLink, index) => (
        <li key={index}>
          <PageLink
            href={quickLink?.menuItem?.url}
            target={quickLink?.menuItem?.target}
          >
            <span className="linkText">{quickLink?.menuItem?.title}</span>
            <img src="/icons/chevron-circle-right.svg" alt="chevron" />
          </PageLink>
        </li>
      ))}
    </ul>
  )
}

function getCopyrightLinks(copyrightLinks) {
  if (!copyrightLinks)
    return <li className="errorMessage">No copyright links assigned</li>

  return (
    <ul>
      {copyrightLinks.map((link, index) => (
        <li key={index}>
          <PageLink href={link?.menuItem?.url} target={link?.menuItem?.target}>
            <TextBlock text={link?.menuItem?.title} />
          </PageLink>
        </li>
      ))}
    </ul>
  )
}

export default Footer
