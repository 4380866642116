export const useModal = (() => {
  let modalInstance

  const createModalInstance = () => {
    let subs = []
    let modal = ""

    const getModalStore = () => {
      return modal
    }

    const setModalStore = newModal => {
      modal = newModal
      subs.map(fx => fx())
      return modal
    }

    const subscribe = fx => {
      subs.push(fx)
    }

    const unsubscribe = fx => {
      let index = subs.indexOf(fx)
      subs.splice(index, 1)
    }

    return [getModalStore, setModalStore, subscribe, unsubscribe]
  }

  return () => {
    if (!modalInstance) {
      modalInstance = createModalInstance()
    }
    return modalInstance
  }
})()
