import React, { useRef, useEffect } from "react"
import HeadingBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import ImageBlock from "../../../elements/imageBlock"

import "./megamenu.scss"
import PageLink from "../../../elements/pageLink"

const MegaMenu = props => {
  const megaMenuRef = useRef(null)

  useEffect(() => {
    megaMenuRef?.current?.focus()
  })

  if (props.megaMenuState) {
    const menuItem = props?.megaMenuState

    const hasMegaMenuItems = menuItem?.megaMenuItems?.menuItems ? true : false;

    return (
      <div
        className="megaMenu"
        ref={megaMenuRef}
        tabIndex={-1}
        onBlur={() => props.deactivateMegaMenu()}
        onMouseEnter={() => {
          props.activateMegaMenu(menuItem)
        }}
        onMouseLeave={() => {
          props.deactivateMegaMenu()
        }}
      >
        <div className="megaMenu-container">
          <div className="megaMenu-column megaMenu-summary">
            <HeadingBlock text={menuItem?.megaMenuItems?.heading} />
            <TextBlock text={menuItem?.megaMenuItems?.bodyText} />
            <PageLink href={menuItem?.url} className="btn btn-primary btn-pill">
              Learn More
            </PageLink>
          </div>
          <div className="megaMenu-column megaMenu-menu">
            <ul>
              {!hasMegaMenuItems ? '' : menuItem?.megaMenuItems?.menuItems.map((menuItem, index) => {
                return (
                  <li key={index}>
                    <PageLink href={menuItem?.url}>
                      <TextBlock text={menuItem?.title} />
                      <img src="/icons/chevron-right-gold.svg" alt="" />
                    </PageLink>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="megaMenu-column megaMenu-image">
            <ImageBlock imageSrc={menuItem?.megaMenuItems?.imageSrc} />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default MegaMenu
